export default {
  handleProductErrors(errorData, errorForm) {
    let error = errorData.errors;
    errorForm.name = error['nome'] ? error['nome'][0] : '';
    errorForm.description = error['descricao'] ? error['descricao'][0] : '';
    errorForm.informationLink = error['link_informacao'] ? error['link_informacao'][0] : '';
    errorForm.externalLink = error['link_pagina'] ? error['link_pagina'][0] : '';
    errorForm.imageFile = error['imagem'] ? error['imagem'][0] : '';
  }
}
